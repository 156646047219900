import superMan from '../../../assets/vectors/super_man.svg';

import aiIcon from '../../../assets/icons/ai.svg';
import aiCoverLetterIcon from '../../../assets/icons/ai-cover-letter.svg';
import aiJobSearch from '../../../assets/icons/job-search.svg';

import { FeaturesPanelProps } from './FeaturesPanel.types';

import {
  BottomWrapper,
  Container,
  FeatureIcon,
  FeatureWrapper,
  ImageWrapper,
  MainFeatureWrapper,
  TopWrapper,
} from './FeaturesPanel.styles';
import { Copy, H4 } from '../../atoms/Typography';
import { Spacer } from '../../atoms/Spacer/Spacer';

export const FeaturesPanel = ({
  title,
  children,
  isFeaturesPanel,
  onActionClick,
  ...otherProps
}: React.PropsWithChildren<FeaturesPanelProps>) => {
  return (
    <Container isFeaturesPanel={isFeaturesPanel} onClick={onActionClick} {...otherProps}>
      <TopWrapper>
        <ImageWrapper>
          <img alt={'Organise'} src={superMan} />
        </ImageWrapper>
        <MainFeatureWrapper>
          <H4>{title}</H4>
          {children}
        </MainFeatureWrapper>
      </TopWrapper>
      <BottomWrapper>
        <FeatureWrapper>
          <FeatureIcon>
            <img alt={'aiIcon'} src={aiIcon} />
          </FeatureIcon>
          <Spacer y={32} />
          <Copy isBold={true} styleLevel={1} marginBottom={16}>
            Craft a Job Winning Resume
          </Copy>
          <Copy styleLevel={2} marginBottom={16}>
            Transform your outdated resume into a standout professional profile with our AI-powered resume builder.
            Choose from over a hundred template configurations to create a personalized resume that captures attention
            and impresses.
          </Copy>
        </FeatureWrapper>
        <FeatureWrapper>
          <FeatureIcon>
            <img alt={'aiCoverLetterIcon'} src={aiCoverLetterIcon} />
          </FeatureIcon>
          <Spacer y={32} />
          <Copy isBold={true} styleLevel={1} marginBottom={16}>
            Cover Letters, Made Simple!
          </Copy>
          <Copy styleLevel={2} marginBottom={16}>
            We understand that finding the perfect words for your cover letter can be more challenging than it appears.
            Our cover letter generator provides you with a head start, tailoring your content based on the specific job
            opportunity you're applying for and your job search preferences.
          </Copy>
        </FeatureWrapper>

        <FeatureWrapper>
          <FeatureIcon>
            <img alt={'aiJobSearch'} src={aiJobSearch} />
          </FeatureIcon>
          <Spacer y={32} />
          <Copy isBold={true} styleLevel={1} marginBottom={16}>
            Import Job Applications
          </Copy>
          <Copy styleLevel={2} marginBottom={16}>
            Elevate your job search journey with our AI assistant. Now, it can seamlessly import job applications from
            URLs, automatically populating all the details for you on your Jobs Board. Effortless and efficient – let AI
            take the lead in simplifying your job application process.
          </Copy>
        </FeatureWrapper>
      </BottomWrapper>
    </Container>
  );
};
