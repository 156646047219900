import styled, { css } from 'styled-components';
import { Colors } from '../../../styles/colors';
import { media } from '../../../styles/media';
import { FeaturesPanelProps } from './FeaturesPanel.types';

export const Container = styled.div<FeaturesPanelProps>``;

export const TopWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  ${media.from_tablet`
    grid-template-columns: 1fr 2fr;
  `}
`;

export const BottomWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  ${media.from_tablet`
    grid-template-columns: repeat(3, 1fr);
  `}
`;

export const FeatureWrapper = styled.div`
  padding: 16px;
`;

export const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MainFeatureWrapper = styled.div`
  padding: 16px;
`;

export const FeatureIcon = styled.div`
  display: flex;
  width: 100px;
  height: 100px;
  justify-content: center;
`;
